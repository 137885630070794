html {
  height: 100%;
  width: 100%;
  @apply bg-gray-950;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
    width: 100%;
    @apply bg-gray-950;
}